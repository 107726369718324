import moment from "moment";
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, DateLogs, ResultResponse } from "./portfolioDesigner";
import { TIME_RANGES, TimeRange } from "../../components/common/ranges";

export enum Metric {
  PRICE = 'price',
  PRICE_RETURNS = 'price_return',
  TOTAL_RETURN = 'total_return',
  DRAWDOWN = 'drawdown',
  ROLLING_CORRELATION = 'rolling_correlation',
  ROLLING_RETURNS = 'rolling_returns',
  MOVING_AVERAGES = 'moving_averages',
}

export const PERCENTAGE_METRICS = [Metric.TOTAL_RETURN, Metric.PRICE_RETURNS, Metric.DRAWDOWN, Metric.ROLLING_CORRELATION, Metric.ROLLING_RETURNS];
export const PRICE_METRICS = [Metric.PRICE, Metric.MOVING_AVERAGES];
  
export type LineWeight =
| 2
| 3
| 4
| 5
| 6
| 7
| 8
| 9
| 10;

export const LINE_WEIGHT_ITEMS: LineWeight[] = [
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10
];

export enum LineType {
SOLID = 'Solid',
DASH = 'Dash',
DOT = 'Dot',
SHORT_DASH = 'ShortDash',
SHORT_DOT = 'ShortDot',
SHORT_DASH_DOT = 'ShortDashDot',
SHORT_DASH_DOT_DOT = 'ShortDashDotDot',
DOT_DASH = 'DashDot',
LONG_DASH = 'LongDash',
DASH_DOT = 'dashdot',
LONG_DASH_DOT = 'LongDashDot',
LONG_DASH_DOT_DOT = 'LongDashDotDot',
}

export const DASH_OPTION = Object.values(LineType)

export interface ChartAnnotations {
  line_weight?: LineWeight;
  line_color?: string;
  line_type?: LineType;
  min_line_enabled?: boolean;
  max_line_enabled?: boolean;
  average_line_enabled?: boolean;
  use_backfill?: boolean;
}

export interface CreateChartAnnotationPayload extends ChartAnnotations {
  symbol: string;
}

export interface DueDiligenceChartAnnotations extends ChartAnnotations {
  id: number;
  user_id: number;
  due_diligence_chart_id: number;
  symbol: string;
}

export interface DueDiligenceChartAnnotationsEdit extends Partial<Exclude<DueDiligenceChartAnnotations, 'user_id'>> {}

export interface DueDiligenceChartDTO {
  id: number;
  user_id: number;
  name: string;
  start_date: string;
  end_date: string;
  date_range?: TimeRange | null;
  tickers: string[];
  selected_tickers: string[];
  metrics: Metric[];
  rc_lag?: number;
  rc_tickers: string[];
  is_system?: boolean;
  models: number[];
  selected_models: number[];
  moving_averages_window_days: number
}

export type TimeseriesValue = Record<string, number | null>;
export type TimeseriesItems = Record<string, TimeseriesValue>;

export interface DueDiligenceStatistics {
  [key: string]: {
    annualized_return: number;
    annualized_return_over_volatility: number;
    annualized_volatility: number;
    expense_ratio: number;
  };
}
export interface DueDiligenceResultDTO extends ResultResponse {
  price: TimeseriesItems;
  total_return: TimeseriesItems;
  drawdown: TimeseriesItems;
  date_logs: DateLogs;
  total_return_statistics: DueDiligenceStatistics;
  price_return_statistics: DueDiligenceStatistics;
  hypotheticalDataUsed: boolean;
};

export interface ScreenerOptions {
  universes?: {
    enabled: boolean;
    options: string[];
  };
  categories?: {
    enabled: boolean;
    options: string[];
  };
  distribution_yield?: {
    enabled: boolean;
    value: number | string;
  };
  expense_ratio?: {
    enabled: boolean;
    value: number | string;
  };
  performance_rank_6mo?: {
    enabled: boolean;
    value: number | string;
  };
  performance_rank_1yr?: {
    enabled: boolean;
    value: number | string;
  };
}


export interface ScreenerResultItem {
  id: string;
  symbol: string;
  symbol_display: string;
  name: string;
  fund_category: string;
  expense_ration: number;
  distribution_yield: number;
  performance_rank_6mo: number;
  performance_rank_1yr: number;
  checked: boolean;
}

export const DEFAULT_NEW_CHART: DueDiligenceChartDTO = {
  id: 0,
  user_id: 0,
  name: '',
  start_date: moment(DEFAULT_MIN_DATE).format('YYYY-MM-DD'),
  end_date: moment(DEFAULT_MAX_DATE).format('YYYY-MM-DD'),
  tickers: [],
  selected_tickers: [],
  metrics: [Metric.TOTAL_RETURN],
  date_range: TIME_RANGES.find((range: TimeRange) => range.unit === 'max'),
  rc_lag: 252,
  rc_tickers: [],
  models: [],
  selected_models: [],
}

export const RC_YAXIS_OPTIONS = {
    tickInterval: 0.1, // Set the interval for the ticks
    tickAmount: 10,
    labels: {
        style: {
            fontSize: '14px'
        },
        formatter: function (this: any): string {
          return this.value.toString();
        },
    },
}

export function yAxisPercentageFormatter(this: any): string {
  const formattedValue = Number.isInteger(this.value) ? this.value.toFixed(this.decimals ?? 0) + '%' : this.value.toFixed(this.decimals ?? 1) + '%';

  return formattedValue;
}

export function yAxisDollarFormatter(this: any): string {
  //without decimal points
  const hideDecimal = Number.isInteger(this.value) && this.decimals === undefined;
  return this.value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: hideDecimal ? 0 : this.decimals ?? 2,
    maximumFractionDigits: hideDecimal ? 0 : this.decimals ?? 2,
  });
}

export const DEFAULT_CHART_OPTIONS = {
            chart: {
                type: 'spline',
                zoomType: 'x',
            },
            title: {
                text: ''
            },
            xAxis: {
              type: 'datetime',
              dateTimeLabelFormats: {
                millisecond: '%H:%M:%S.%L ',
                second: '%H:%M:%S',
                minute: '%H:%M',
                hour: '%H:%M',
                day: '%m/%d/%Y',
                week: '%m/%d/%Y',
                month: '%m/%Y',
                year: '%Y'
              },
              labels: {
                rotation: 45,  // Rotate labels by 45 degrees
                  style: {
                      fontSize: '14px',
                  },
              },
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    style: {
                        fontSize: '14px'
                    }
                },
                min: 0,  // Starting point
                max: 100 // End point, you can adjust as needed
            },
            plotOptions: {
                spline: {
                    dataLabels: {
                        enabled: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            legend: {
                enabled: false,
                verticalAlign: 'top',
                symbolWidth: 35,
                symbolHeight: 1,
                symbolRadius: 1,
                squareSymbol: true,
                itemStyle: {
                    fontSize: '14px',
                }
            },
            tooltip: {
                formatter: function(): any {
                    const point = (this as any).point
                    const metricFormatted =  point.metric.split('_').map((token: string) => token.charAt(0).toUpperCase() + token.slice(1)).join(' ');
    
                    const name = point.metric === Metric.DRAWDOWN ? metricFormatted : point.series.name
                    const date = moment.utc(point.x).format('MMM DD, YYYY')
                    
                    const value = PERCENTAGE_METRICS.includes(point.metric) && point.metric !== Metric.ROLLING_CORRELATION ? point.y.toFixed(2) + '%' 
                      : PRICE_METRICS.includes(point.metric) ? point.y.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) 
                      : point.y.toFixed(2)
    
                    return name + '<br />' + date + '<br />' + value;
                },
                style: {
                    fontSize: '14px'
                }
             },
            series: [{
                name: '',
                data: [],
                turboThreshold: 200000,
            }]
        }

export type Order = 'asc' | 'desc';

export const DD_STATISTICS_FORMATTING: { [key: string]: any } = {
  expense_ratio: (value: number) => ((value / 100)?.toFixed(2) || '--' )+ '%',
  annualized_volatility: (value: number) => ((value * 100)?.toFixed(2) || '--') + '%',
  annualized_return_over_volatility: (value: number) => value?.toFixed(2) || '--',
  annualized_return: (value: number) => ((value * 100)?.toFixed(2) || '--') + '%',
}