import { Component } from "react"
import { connect } from 'react-redux'
import { ResultsTableData } from "../../../actions/types/portfolioDesigner"
import { ReduxState } from "../../../reducers"
import { Box } from "@mui/material"
import { ChartOptions } from "../../../components/OptimizedPortfolio/chartOptions"
import Chart from "../../../components/common/Chart"
import ExtendedCommonTable from "../../../components/common/ExtendedCommonTable"
import { TableRowData } from "../../../common/types"
import Text from "../../../components/common/Text"
import { ClipLoader } from "react-spinners"
import { onEffChartMarkerClicked, runPortfolioOptimization } from "../../../actions/portfolioDesigner"
import Button from "../../../components/common/Button"
import ConfirmModal from "../../../components/common/ConfirmModal"
import { TextFieldItem } from "../../../components/common/FormField"

export interface EfficientFrontierProps {
    effChart: ChartOptions
    effTable: {
        rows: TableRowData[]
        columns: string[]
    }
    runningEfficencyFrontier: boolean
    requestId: string
    onEffChartMarkerClicked: (data: any) => void
    runPortfolioOptimization: (requestId: string, minimumInvestmentAllocation: number, maximumInvestmentAllocation: number) => void
}
export interface EfficientFrontierState { 
    effChart?: ChartOptions
    showInvestmentAllocationModal: boolean
    minimumInvestmentAllocation: number
    maximumInvestmentAllocation: number
}
class EfficientFrontier extends Component<EfficientFrontierProps, EfficientFrontierState> {
    mainView?: any
    resultsRef?: any

    constructor(props: EfficientFrontierProps) {
        super(props)

        this.state = {
            effChart: this.addClickHandlersToChartOptions(props.effChart),
            showInvestmentAllocationModal: false,
            minimumInvestmentAllocation: 1.0,
            maximumInvestmentAllocation: 50,
        };
    }

    addClickHandlersToChartOptions = (chartOptions: ChartOptions): ChartOptions | undefined => {
        if (!chartOptions) return undefined
        return {
            ...chartOptions,
            plotOptions: {
                scatter: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: this.onMarkerClicked
                        }
                    }
                },
                spline: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: this.onMarkerClicked
                        }
                    }
                }
            },
        }
    }

    componentDidUpdate(prevProps: Readonly<EfficientFrontierProps>, prevState: Readonly<EfficientFrontierState>, snapshot?: any): void {
        if (prevProps.effChart !== this.props.effChart) {
            this.setState({ effChart: this.addClickHandlersToChartOptions(this.props.effChart) })
        }
    }

    onMarkerClicked = ({point}: any) => {
        const { markerData } = point
        this.props.onEffChartMarkerClicked(markerData)
    }

    onShowInvestmentAllocationModal = () => {
        this.setState({ showInvestmentAllocationModal: true })
    }

    onCloseInvestmentAllocationModal = () => {
        this.setState({ showInvestmentAllocationModal: false })
    }

    onRunEfficientFrontier = () => {
        const { requestId } = this.props
        const { minimumInvestmentAllocation, maximumInvestmentAllocation } = this.state
        this.props.runPortfolioOptimization(requestId, minimumInvestmentAllocation / 100, maximumInvestmentAllocation / 100)
        this.setState({ showInvestmentAllocationModal: false })
    }

    onUpdateMinimumInvestmentAllocation = (value: string) => {
        const valueAsNumber = parseFloat(value)
        this.setState({ minimumInvestmentAllocation: valueAsNumber })
    }
    onUpdateMaximumInvestmentAllocation = (value: string) => {
        const valueAsNumber = parseFloat(value)
        this.setState({ maximumInvestmentAllocation: valueAsNumber })
    }


    investmentAllocationFormatter = (value: string) => {
        let cleaned = value.toString().replace(/[^0-9.]/g, '');
        if (cleaned.endsWith('.')) {
            const parts = cleaned.split('.');
            if (parts.length > 2) {
                cleaned = parts[0] + '.' + parts.slice(1).join('');
            }
            if (!isNaN(parseFloat(cleaned))) {
                return cleaned;
            }
        }
        const valueTest = parseFloat(cleaned)
        
        if((value && value.length > 0) && isNaN(valueTest)) {
            return value;
        }

        return cleaned;
    }

    onMinimumInvestmentAllocationLeave = () => {
        const { minimumInvestmentAllocation, maximumInvestmentAllocation } = this.state
        if (minimumInvestmentAllocation < 0.00) {
            this.setState({ minimumInvestmentAllocation: 0.00 })
        } 
        
        if (minimumInvestmentAllocation > 100.00) {
            this.setState({ minimumInvestmentAllocation: 100.00 })
        }
        if (minimumInvestmentAllocation > maximumInvestmentAllocation) {
            this.setState({ maximumInvestmentAllocation: minimumInvestmentAllocation })
        } 
    }

    onMaximumInvestmentAllocationLeave = () => {
        const { minimumInvestmentAllocation, maximumInvestmentAllocation } = this.state
        if (minimumInvestmentAllocation > maximumInvestmentAllocation) {
            this.setState({ maximumInvestmentAllocation: minimumInvestmentAllocation })
        }
        if (maximumInvestmentAllocation < 0) {
            this.setState({ maximumInvestmentAllocation: minimumInvestmentAllocation })
        } 
        
        if (maximumInvestmentAllocation > 100.00) {
            this.setState({ maximumInvestmentAllocation: 100.00 })
        }
    }

    render() {
        const { effTable, runningEfficencyFrontier } = this.props
        const { effChart: effChartOptions, showInvestmentAllocationModal, minimumInvestmentAllocation, maximumInvestmentAllocation } = this.state
        if (runningEfficencyFrontier || !effChartOptions || !effTable) {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center', minHeight: '25rem'}}>
                    <Box><Text>Calculating...</Text></Box>
                    <Box><ClipLoader /></Box>
                </Box>
            )
        }
        const { rows, columns } = effTable
        return (
            <Box>
            <ConfirmModal
                title='Efficient Frontier'
                visible={showInvestmentAllocationModal}
                onCancel={this.onCloseInvestmentAllocationModal}
                onConfirm={this.onRunEfficientFrontier}
                size={'sm'}
                confirmLabel={'Run'}>
                <TextFieldItem
                    sx={{ marginTop: 'unset'}}
                    type="number"
                    inputProps={{ 
                        min: 0.0,
                        max: 100.0,
                        step: 1 
                }}
                    title={'Minimum Investment Allocation (0% - 100%)'}
                    value={minimumInvestmentAllocation}
                    onValueChange={this.onUpdateMinimumInvestmentAllocation}
                    onLeave={this.onMinimumInvestmentAllocationLeave}
                    valueFormatter={this.investmentAllocationFormatter}
                />
                <TextFieldItem
                    sx={{ marginTop: 'unset'}}
                    type="number"
                    inputProps={{ 
                        min: 0.0,
                        max: 100.0,
                        step: 1 
                }}
                    title={'Maximum Investment Allocation (0% - 100%)'}
                    value={maximumInvestmentAllocation}
                    onValueChange={this.onUpdateMaximumInvestmentAllocation}
                    onLeave={this.onMaximumInvestmentAllocationLeave}
                    valueFormatter={this.investmentAllocationFormatter}
                />
            </ConfirmModal>
                {/* <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', mt: '1rem'}}>
                    <Button onClick={this.onShowInvestmentAllocationModal}>Investment Constraints</Button>
                </Box> */}
                <Chart id={'effChart'} sx={{ height: '75rem', width: '100rem'}} containerSx={{ justifyContent: 'center' }} chartOptions={effChartOptions} zoomOptions={{ enabled: true }} />
                <Box sx={{  mt: '5rem', display: 'flex', justifyContent: 'center' }}>
                    <ExtendedCommonTable
                        sx={{ maxWidth: '125rem',}}
                        headerColumns={
                        [
                                {
                                    key: '',
                                    children: '',
                                    colSpan: 1,
                                },
                                ...columns.map((column: string) => {
                                    return {
                                        key: column,
                                        children: column,
                                        sx: {
                                            textAlign: 'center',
                                        }
                                    }
                                })
                        ]
                        }
                        centerValues={true}
                        rows={rows}
                    />
                </Box>
            </Box>
        )
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState ) => {
    const { effChart, effTable, runningEfficencyFrontier, requestId } = portfolioDesigner
    
    return { effChart, effTable, runningEfficencyFrontier, requestId }
}

export default connect(mapStateToProps, { onEffChartMarkerClicked, runPortfolioOptimization })(EfficientFrontier as  any)