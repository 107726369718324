import { Component } from 'react'
import { connect } from 'react-redux';
import { Box, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

import ConfirmModal from '../../common/ConfirmModal'
import { ReduxState } from '../../../reducers';
import { addError } from '../../../actions/notifications';
import { DueDiligenceChartDTO } from '../../../actions/types/dueDiligence'
import { FONT_STYLES_BY_TYPE } from '../../../components/common/styled/Text';
import { NumericFilterInput } from '../Screener/NumericFilterInput';

interface ComponentProps {
    visible: boolean
    onConfirm: (chart: DueDiligenceChartDTO) => void
    onCancel: () => void
}

interface ReduxStateProps {
    chart: DueDiligenceChartDTO
}

interface ReduxActionProps {
    addError: (title: string, message: string) => void
}

interface MovingAveragesModelInputsModalState {
    moving_averages_window_days: number
}

type MovingAveragesModelInputsModalProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class MovingAveragesModelInputsModal extends Component<MovingAveragesModelInputsModalProps, MovingAveragesModelInputsModalState> {
    selectedLinePath: any;

    constructor(props: MovingAveragesModelInputsModalProps) {
        super(props);
        this.state = {
            moving_averages_window_days: 30,
        }
    }

    componentDidUpdate(prevProps: Readonly<MovingAveragesModelInputsModalProps>, prevState: Readonly<MovingAveragesModelInputsModalState>, snapshot?: any): void {
        if(prevProps.chart.moving_averages_window_days !== this.props.chart.moving_averages_window_days) {
            this.setState({moving_averages_window_days: this.props.chart.moving_averages_window_days || 30})
        }
    }

    
    onConfirm = () => {
        if(!this.props.onConfirm) {
            return
        }
        const {moving_averages_window_days} = this.state
        this.props.onConfirm({
            ...this.props.chart,
            moving_averages_window_days,
        })
    }

    onValueChanged = (value: string | number) => {
        this.setState({ moving_averages_window_days: Number(value) })
    }

    render() {
        const { visible } = this.props
        const { moving_averages_window_days } = this.state

        return (
            <ConfirmModal
                visible={visible}
                title={`Moving Average`}
                confirmLabel={'Confirm'}
                cancelLabel={'Cancel'}
                onConfirm={this.onConfirm}
                onCancel={this.props.onCancel}>
                <NumericFilterInput
                    title={'Moving Average Window (Trading Days)'}
                    value={moving_averages_window_days}
                    isNumeric={true}
                    onValueChanged={this.onValueChanged}
                    inline={false}
                />
                
            </ConfirmModal>
        )
    }
}

const mapStateToProps = ({dueDiligence}: ReduxState) => ({
    chart: dueDiligence.selectedChartItem,
})

export default connect(mapStateToProps, { addError })(MovingAveragesModelInputsModal)